import React from 'react';
import Styles from './MessageInput.module.scss';
import { Button, Image, ProgressBar } from 'react-bootstrap';
import { MdClear } from 'react-icons/md';
import moment from 'moment';
import { CHAT_EVENT_TYPE, CONTENT_TYPE } from '../../const/ChatConst.js';
import { observer } from 'mobx-react';
import { debounce } from 'lodash';
import QuickMsg from './QuickMsg.js';
import {
  AddSquare,
  AttachSquare,
  CloseCircle,
  CloseSquare,
  Gallery,
  Send2,
} from 'iconsax-react';
import CustomButton from '../button/CustomButton.js';
import ContractionMessage from './message/ContractionMessage.js';
import CommonHelper from '../../helper/CommonHelper.js';
import { FileContent } from './message/FileContent.js';

const MIN_HEIGHT = 40;

@observer
class MessageInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      scrollHeight: MIN_HEIGHT,
    };
  }

  imgRef;
  fileRef;

  componentDidMount() {
    const { store } = this.props;
    store.setQuickMsgFn(this.quickMsgSet);
  }

  onChange = (e) => {
    const elem = e.target;
    const MAX_HEIGHT = 136;
    let scrollHeight;
    if (elem && elem.scrollHeight >= MIN_HEIGHT) {
      if (MAX_HEIGHT < elem.scrollHeight) {
        scrollHeight = `${MAX_HEIGHT}px`;
      } else {
        scrollHeight = `${elem.scrollHeight}px`;
      }
    } else if (elem && elem.scrollHeight <= MIN_HEIGHT) {
      scrollHeight = MIN_HEIGHT;
    } else {
      scrollHeight = MIN_HEIGHT;
    }

    this.setState(
      {
        value: e.target.value,
        scrollHeight:
          e.target.value == '' || e.target.scrollHeight < MIN_HEIGHT
            ? MIN_HEIGHT
            : scrollHeight,
      },
      () => {
        this.typingEnd();
      },
    );
  };

  onSendMessage = async (value, type, file) => {
    let { store } = this.props;
    let message = {
      content: {
        type,
        value,
      },
      contentType: type,
      createdAt: moment(),
    };
    return (await store.onSendMessage) && store.onSendMessage(message, file);
  };

  debounceTypingStart = debounce(
    () => {
      let { store } = this.props;
      store.sendChatEvent(CHAT_EVENT_TYPE.TYPING_START);
    },
    200,
    { leading: true },
  );

  debounceQuickMsg = debounce(() => {
    const { store } = this.props;
    store.quickMsgStore.load(this.state.value);
  }, 200);

  quickMsgSet = (msg) => {
    this.setState({
      value: msg,
    });
  };

  typingEnd = () => {
    const { store } = this.props;
    if (store.quickMsgStore) {
      if (this.state.value.startsWith('#')) {
        if (!store.quickMsgStore.isOpen) {
          store.quickMsgStore.setIsOpen(true);
          console.log(store.quickMsgStore.isOpen);
        }
        this.debounceQuickMsg();
      } else {
        if (store.quickMsgStore.isOpen) {
          store.quickMsgStore.setIsOpen(false);
        }
      }
    }
    if (!this.state.value) {
      this.debounceTypingStart.cancel();
      store.sendChatEvent(CHAT_EVENT_TYPE.TYPING_END);
    } else {
      this.debounceTypingStart();
    }
  };

  render() {
    let { store, isFileSendAble = false, isImageSendAble = false } = this.props;
    return (
      <div className={`position-relative`}>
        <div className={Styles.UploadedFile}>
          {store.uploadedFile && (
            <>
              <div
                className={Styles.Reply}
                style={{
                  backgroundColor:
                    CONTENT_TYPE.IMAGE === store.uploadedType
                      ? 'var(--qt-ch-warning)'
                      : 'var(--qt-ch-active)',
                }}
              >
                <div className={`${Styles.Content} ${Styles.FileText}`}>
                  {store.uploadedType === CONTENT_TYPE.IMAGE ? (
                    <span>
                      <Gallery size={16} />
                    </span>
                  ) : (
                    <span>
                      <AttachSquare size={16} />
                    </span>
                  )}
                  <span>{store.uploadedFile.name}</span>
                  <span>
                    {CommonHelper.getByteSize(store.uploadedFile.size)}
                  </span>
                </div>
                <a
                  className={Styles.Clear}
                  onClick={() => {
                    store.clearUploadFile();
                  }}
                >
                  <MdClear size={24} />
                </a>
              </div>
              <div className={Styles.ImageContainer}>
                <div className={Styles.Image}>
                  {store.uploadedType === CONTENT_TYPE.IMAGE ? (
                    <Image
                      src={`${store.rsocketStore?.apiBaseUrl}/app/preview/${store?.uploadedFile?.url}`}
                      style={{
                        objectFit: 'contain',
                        height: 'auto',
                        width: 'auto',
                      }}
                    />
                  ) : (
                    <div
                      className={`d-flex justify-content-center align-items-center`}
                    >
                      <FileContent
                        value={store.uploadedFile}
                        store={store}
                        isBubble={true}
                      />
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
        <div className={`position-relative`}>
          {store.replyMessage && (
            <div className={Styles.Reply}>
              <div>
                <div className={Styles.Name}>
                  {store.replyMessage.sender.name} 님에게 답장
                </div>
                <div className={Styles.Content}>
                  <ContractionMessage message={store.replyMessage} />
                </div>
              </div>
              <a
                className={Styles.Clear}
                onClick={() => {
                  store.clearReply();
                }}
              >
                <CloseCircle />
              </a>
            </div>
          )}
          <div className={Styles.QuickMsg}>
            <QuickMsg store={store} />
          </div>
        </div>
        {store.isFileUploading && (
          <ProgressBar
            now={store.progress}
            variant={
              CONTENT_TYPE.IMAGE === store.uploadedType ? 'warning' : 'info'
            }
            animated
            style={{
              height: 3,
              borderRadius: 0,
              zIndex: 2,
              position: 'relative',
            }}
          />
        )}
        <div className={Styles.MessageInput}>
          <div className={Styles.MessageInputContent}>
            {/* 사진 첨부 */}
            {/* {(isFileSendAble || isImageSendAble) && (
              <div className={Styles.OptionOpener}>
                <CustomButton
                  className={Styles.ButtonWrapper}
                  disabled={!store.channelId}
                  onClick={() => {
                    store.isOptionOpen = !store.isOptionOpen;
                  }}
                >
                  {store.isOptionOpen ? (
                    <CloseSquare size={28} />
                  ) : (
                    <AddSquare size={28} />
                  )}
                </CustomButton>
              </div>
            )} */}
            <textarea
              className={Styles.TextArea}
              rows={1}
              maxLength={5000}
              placeholder={`내용을 입력해 주세요.`}
              value={this.state.value}
              onChange={this.onChange}
              style={{ height: this.state.scrollHeight }}
              disabled={!store.channelId}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && !e.shiftKey) {
                  if (!store.isSendAble) return;
                  if (e.nativeEvent.isComposing) return;
                  e.preventDefault();
                  if (!this.state.value && !store.uploadedFile) return;
                  this.setState({ value: '', scrollHeight: 57 }, () =>
                    this.typingEnd(),
                  );
                  this.onSendMessage(this.state.value, CONTENT_TYPE.TEXT).then(
                    () => {
                      this.setState({ scrollHeight: MIN_HEIGHT });
                      store.debounceScrollToBottom();
                      this.debounceTypingStart.cancel();
                      store.clearReply();
                      store.clearUploadFile();
                    },
                  );
                }
                // else if (e.key == 'Backspace' || e.key == 'Delete') {
                // }
              }}
            />
            <CustomButton
              className={`${Styles.SendButton} ${Styles.ButtonWrapper}`}
              style={
                !this.state.value || !store.uploadedFile
                  ? {}
                  : { cursor: 'not-allowed' }
              }
              disabled={
                !store.channelId ||
                (!this.state.value.trim() && !store.uploadedFile)
              }
              onClick={() => {
                if (!this.state.value && !store.uploadedFile) return;
                this.onSendMessage(this.state.value, CONTENT_TYPE.TEXT).then(
                  () => {
                    this.setState({ scrollHeight: MIN_HEIGHT });
                    store.debounceScrollToBottom();
                    store.clearReply();
                    store.clearUploadFile();
                  },
                );
                this.setState({ value: '', scrollHeight: MIN_HEIGHT }, () => {
                  this.typingEnd();
                });
              }}
            >
              <Send2
                size={28}
                style={{ opacity: this.state.value ? 1 : 0.5 }}
              />
            </CustomButton>
          </div>
          {(isImageSendAble || isFileSendAble) && (
            <div
              className={Styles.Option}
              style={{ height: store.isOptionOpen ? '6.5rem' : 0 }}
            >
              {isImageSendAble && (
                <div
                  className={`${Styles.OptionWrap} text-center `}
                  onClick={() => {
                    this.imgRef.click();
                  }}
                >
                  <input
                    type={'file'}
                    ref={(ref) => (this.imgRef = ref)}
                    className={`d-none`}
                    accept="image/*"
                    onChange={(e) => {
                      if (e.target.files.length > 0) {
                        const formData = new FormData();
                        formData.append('file', e.target.files[0]);
                        store.uploadedType = CONTENT_TYPE.IMAGE;
                        store.uploadFile(formData).then((media) => {
                          this.imgRef.value = '';
                          store.uploadedFile = media;
                        });
                      }
                    }}
                  />
                  <Button
                    className={Styles.Icon}
                    style={{ backgroundColor: `var(--qt-ch-warning)` }}
                  >
                    <Gallery color={`var(--qt-gray1)`} size={'2rem'} />
                  </Button>
                  <div style={{ color: 'var(--qt-cool-gray7)' }}>앨범</div>
                </div>
              )}
              {isFileSendAble && (
                <div
                  className={`${Styles.OptionWrap} text-center`}
                  onClick={() => {
                    this.fileRef.click();
                  }}
                >
                  <input
                    type={'file'}
                    ref={(ref) => (this.fileRef = ref)}
                    className={`d-none`}
                    onChange={(e) => {
                      if (e.target.files.length > 0) {
                        const formData = new FormData();
                        formData.append('file', e.target.files[0]);
                        store.uploadedType = CONTENT_TYPE.FILE;
                        store.uploadFile(formData).then((media) => {
                          this.fileRef.value = '';
                          store.uploadedFile = media;
                        });
                      }
                    }}
                  />
                  <Button
                    className={Styles.Icon}
                    style={{ backgroundColor: `var(--qt-primary2)` }}
                  >
                    <AttachSquare color={'var(--qt-gray1)'} size={'2rem'} />
                  </Button>
                  <div style={{ color: 'var(--qt-cool-gray7)' }}>파일</div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export { MessageInput };
